import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";

import { fetchStock } from "../util/Http";
import { Stock } from "../models/Stock";
import StockCard from "../components/ui/StockCard";
import LoadingOverlay from "../components/ui/LoadingOverlay";
import { logEvent } from "../util/Amplitude";
import { AmplitudeEventsEnum } from "../models/AmplitudeEvents";
import StockTabs from "../components/ui/StockTabs";
import { Box } from "@mui/material";

const StockPage: React.FC = () => {
  const { stock } = useParams<{ stock: string }>();

  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: `stock page: ${stock}` });
  }, [stock]);

  const { data, isLoading, isError } = useQuery<Stock>({
    queryKey: [stock],
    queryFn: () => fetchStock(stock as string),
  });

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isError) {
    return <h1>Stock Not Found</h1>;
  }

  return (
    <Box
      sx={{
        mx: 2,
        mt: 2,
        width: { md: "800px", lg: "1000px" },
        justifySelf: { md: "center" },
      }}
    >
      <StockCard key={data!.symbol} stock={data!} />
      <StockTabs stockName={stock || ""} />
    </Box>
  );
};

export default StockPage;
