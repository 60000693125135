import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
import StockOverview from "./StockOverview";
import Level2Table from "./Level2Table";
import DilutionChart from "./DilutionChart";

interface StockTabsProps {
  stockName: string;
}

export default function StockTabs({ stockName }: StockTabsProps) {
  return (
    <Tabs defaultValue="overview">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="overview">Overview</TabsTrigger>
        <TabsTrigger value="level2">Level 2</TabsTrigger>
        <TabsTrigger value="dilution">Dilution</TabsTrigger>
      </TabsList>
      <TabsContent value="overview">
        <StockOverview stock={stockName}/>
      </TabsContent>
      <TabsContent value="level2">
        <Level2Table stock={stockName} />
      </TabsContent>
      <TabsContent value="dilution">
        <DilutionChart stock={stockName} />
      </TabsContent>
    </Tabs>
  );
}
