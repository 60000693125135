import React from "react";

import { Card, CardContent, CardHeader, CardTitle } from "./card";
import { Box } from "@mui/material";

import { Stock } from "../../models/Stock";
import { Badge } from "./badge";

interface StockCardProps {
  stock: Stock;
}

const StockCard: React.FC<StockCardProps> = ({ stock }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-2xl font-bold">{stock.symbol}</CardTitle>
          {stock.tier !== "None" && <Badge>{stock.tier}</Badge>}
        </CardHeader>
        <CardContent>
          <div className="flex justify-between items-end">
            <div>
              <p className="text-sm text-muted-foreground">{stock.name}</p>
              <p className="text-3xl font-bold">${stock.last_price}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default StockCard;
