import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

import HomePage from "./pages/HomePage";
import StockPage from "./pages/StockPage";
import Layout from "./components/layout/Layout";
import { initAmplitude } from "./util/Amplitude";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "stocks/:stock",
        element: <StockPage />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    initAmplitude();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
