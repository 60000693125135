import axios from 'axios';

const API_STOCKS_URL = `${process.env.REACT_APP_API_BACKEND_URL}/stocks`;

export async function fetchStocks() {
  const response = await axios.get(API_STOCKS_URL);
  return response.data;
}

export async function fetchStock(stock: string) {
  const response = await axios.get(`${API_STOCKS_URL}/${stock}`);
  return response.data;
}

export async function fetchStockBySearchTerm(searchTerm: string) {
  const response = await axios.get(`${API_STOCKS_URL}/search_stock/${searchTerm}`);
  return response.data;
}

export async function fetchLevel2Data(stock: string) {
  const response = await axios.get(`${API_STOCKS_URL}/${stock}/lvl2`);
  return response.data;
}

export async function fetchDilutionData(stock: string) {
  const response = await axios.get(`${API_STOCKS_URL}/${stock}/shares-structure`);
  return response.data;
}