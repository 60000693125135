export function formatNumberInput(input: number | string): string {
    let num: number;

    if (typeof input === 'number') {
        num = input;
    } 
    else if (typeof input === 'string') {
        const sanitizedInput = input.replace(/,/g, '');
        num = parseFloat(sanitizedInput);
        
        if (isNaN(num)) {
            throw new Error("Invalid number string");
        }
    } else {
        throw new Error("Input must be a number or a string representing a number");
    }
    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'm';
    } else if (num >= 10_000) {
        return (num / 1_000).toFixed(1) + 'k';
    }
    
    return num.toString();
}
