import React from "react";
import { Outlet } from "react-router-dom";
import SearchAppBar from "../ui/StockNavBar";

const Layout: React.FC = () => {
  return (
    <div>
      <SearchAppBar />
      <Outlet />
    </div>
  );
};

export default Layout;
