export default function useIsMarketActive() {
  const MARKET_OPEN_HOUR = 9;
  const MARKET_OPEN_MINUTE = 30;
  const MARKET_CLOSE_HOUR = 16;
  const MARKET_CLOSE_MINUTE = 0;

  const now = new Date();
  const nyTime = new Date(
    now.toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  const day = nyTime.getDay();
  const hour = nyTime.getHours();
  const minute = nyTime.getMinutes();

  // Check if it's a weekday (Monday = 1, Friday = 5)
  const isWeekday = day > 0 && day < 6;

  // Convert current time to minutes since midnight for easier comparison
  const currentTimeInMinutes = hour * 60 + minute;
  const marketOpenInMinutes = MARKET_OPEN_HOUR * 60 + MARKET_OPEN_MINUTE;
  const marketCloseInMinutes = MARKET_CLOSE_HOUR * 60 + MARKET_CLOSE_MINUTE;

  const isMarketHours =
    currentTimeInMinutes >= marketOpenInMinutes &&
    currentTimeInMinutes <= marketCloseInMinutes;

  return isWeekday && isMarketHours;
}
