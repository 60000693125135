import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Checkbox } from "./checkbox";
import { Card, CardContent, CardHeader, CardTitle } from "./card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./chart";
import { DilutionData, VisibleSeries } from "../../models/Dilution";
import { fetchDilutionData } from "../../util/Http";
import getDilutionDataset from "../../util/getDilutionDataset";
import { Loader2, AlertCircle, LineChart as LineChartIcon } from "lucide-react";
import ChartLoadingAnimation from "./ChartLoadingAnimation";

const lineConfig = [
  {
    id: "authorizedShares",
    label: "Authorized Shares",
    color: "hsl(var(--chart-4))",
  },
  {
    id: "unrestrictedShares",
    label: "Unrestricted Shares",
    color: "hsl(var(--chart-1))",
  },
  {
    id: "restrictedShares",
    label: "Restricted Shares",
    color: "hsl(var(--chart-2))",
  },
  { id: "dtcShares", label: "DTC Shares", color: "hsl(var(--chart-3))" },
];

interface DilutionDataProps {
  stock: string;
}

const formatNumber = (value: number) => {
  if (value >= 1000000000) {
    return `${parseFloat((value / 1000000000).toFixed(1))}B`;
  }
  if (value >= 1000000) {
    return `${parseFloat((value / 1000000).toFixed(1))}M`;
  }
  if (value >= 1000) {
    return `${parseFloat((value / 1000).toFixed(1))}K`;
  }
  return value.toString();
};

const formatDate = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

export default function DilutionChart({ stock }: DilutionDataProps) {
  const [visibleSeries, setVisibleSeries] = useState<VisibleSeries>({
    unrestrictedShares: true,
    restrictedShares: true,
    dtcShares: true,
    authorizedShares: true,
  });

  const { data, isLoading, isError } = useQuery<DilutionData>({
    queryKey: ["DilutionData", stock],
    queryFn: () => fetchDilutionData(stock),
  });

  const handleToggle = (seriesId: keyof VisibleSeries) => {
    setVisibleSeries((prev) => ({
      ...prev,
      [seriesId]: !prev[seriesId],
    }));
  };

  const { chartData, activeSeriesData } = useMemo(() => {
    if (!data) return { chartData: [], activeSeriesData: [] };

    const { chartDates, seriesData } = getDilutionDataset(data);
    const activeSeriesData = seriesData.filter(
      (series) => visibleSeries[series.id as keyof VisibleSeries]
    );

    const allDataPoints = chartDates.map((date, index) => {
      const dataPoint: any = {
        name: formatDate(new Date(date.dates)),
        date: date.dates,
      };
      seriesData.forEach((series) => {
        if (visibleSeries[series.id as keyof VisibleSeries]) {
          dataPoint[series.id] = series.data[index];
        }
      });
      return dataPoint;
    });

    const firstValidIndex = allDataPoints.findIndex((point) =>
      Object.keys(visibleSeries).some(
        (key) =>
          visibleSeries[key as keyof VisibleSeries] &&
          point[key] !== undefined &&
          point[key] !== null
      )
    );

    const filteredData =
      firstValidIndex >= 0
        ? allDataPoints.slice(firstValidIndex)
        : allDataPoints;

    return {
      chartData: filteredData,
      activeSeriesData,
    };
  }, [data, visibleSeries]);

  const chartConfig = useMemo(() => {
    const config: Record<
      string,
      {
        label: string;
        color: string;
        valueFormatter: (value: number) => string;
      }
    > = {};
    lineConfig.forEach((line) => {
      config[line.id] = {
        label: line.label,
        color: line.color,
        valueFormatter: formatNumber,
      };
    });
    return config;
  }, []);

  if (isLoading) {
    return (
      <Card className="w-full">
        <CardContent className="flex justify-center items-center h-80">
          <ChartLoadingAnimation />
        </CardContent>
      </Card>
    );
  }

  const hasNoData =
    data &&
    [
      "authorizedShares",
      "restrictedShares",
      "unrestrictedShares",
      "dtcShares",
    ].every((key) => data[key as keyof DilutionData].values.length === 0);

  if (isError || !data) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col justify-center items-center h-80 space-y-4">
          <div className="text-destructive/80 animate-pulse">
            <AlertCircle className="h-12 w-12" />
          </div>
          <div className="text-center space-y-2">
            <h3 className="font-medium text-lg text-destructive">
              Unable to Load Dilution Chart
            </h3>
            <p className="text-muted-foreground text-sm max-w-[250px]">
              We encountered an issue while fetching the dilution data. Please
              try again later.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (hasNoData) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col justify-center items-center h-80 space-y-4">
          <div className="text-muted-foreground/80">
            <LineChartIcon className="h-12 w-12" />
          </div>
          <div className="text-center space-y-2">
            <h3 className="font-medium text-lg">
              No Share Structure Data Available
            </h3>
            <p className="text-muted-foreground text-sm max-w-[300px]">
              We currently don't have any share structure data for this stock.
              This information may be added in future updates.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-lg sm:text-xl">
          Dilution History Chart
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-4 mb-4">
          {lineConfig.map((line) => (
            <div key={line.id} className="flex items-center space-x-2">
              <Checkbox
                id={line.id}
                checked={visibleSeries[line.id as keyof VisibleSeries]}
                onCheckedChange={() =>
                  handleToggle(line.id as keyof VisibleSeries)
                }
              />
              <label
                htmlFor={line.id}
                className="text-xs sm:text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {line.label}
              </label>
            </div>
          ))}
        </div>

        <ChartContainer
          config={chartConfig}
          className="lg:h-[500px] w-full mt-4"
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 5, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => {
                  const d = new Date(date);
                  return `${d.getMonth() + 1}/${d.getFullYear().toString().slice(2)}`;
                }}
                interval="preserveStartEnd"
              />
              <YAxis width={35} tickFormatter={formatNumber} />
              <ChartTooltip content={<ChartTooltipContent />} />
              {activeSeriesData.map((series) => {
                const config = lineConfig.find((l) => l.id === series.id);
                if (!config) return null;
                return (
                  <Line
                    key={series.id}
                    type="monotone"
                    dataKey={series.id}
                    name={config.label}
                    stroke={`var(--color-${series.id})`}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
