import { useQuery } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";
import { Alert, AlertDescription } from "./alert";
import { Card, CardContent } from "./card";
import { Loader2, AlertCircle } from "lucide-react";
import { fetchLevel2Data } from "../../util/Http";
import { Level2 } from "../../models/Level2";
import { formatNumberInput } from "../../util/formatNumberInput";
import useIsMarketActive from "../../hooks/useIsMarketActive";
import MarketHoursCard from "./MarketHoursCard";

interface Level2TableProps {
  stock: string;
}

export default function Level2Table({ stock }: Level2TableProps) {
  const marketActive = useIsMarketActive();
  const { data, isLoading, isError } = useQuery<Level2>({
    queryKey: ["level2", stock],
    queryFn: () => fetchLevel2Data(stock),
    enabled: marketActive,
    retry: false,
  });

  if (!marketActive) {
    return <MarketHoursCard />;
  }

  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center gap-2 pt-6">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>Loading Level 2 Data ...</span>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card className="mx-auto max-w-md">
        <CardContent className="pt-6">
          <Alert variant="destructive">
            <AlertDescription className="text-center">
              Error Fetching Level 2 Data
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  if (!data?.bid?.length && !data?.ask?.length) {
    return (
      <Card className="mx-auto max-w-lg">
        <CardContent className="flex flex-col items-center justify-center gap-4 py-12">
          <AlertCircle className="h-12 w-12 text-gray-400" />
          <div className="text-center">
            <h3 className="text-lg font-semibold text-gray-900">
              No Level 2 Data Available
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              Level 2 market data is currently unavailable for {stock}. This
              could be due to low trading volume or market conditions.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={3} className="text-center">
              Bid
            </TableHead>
          </TableRow>
          <TableRow>
            <TableHead>MM</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Size</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.bid?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.marketMaker}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{formatNumberInput(item.size)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={3} className="text-center">
              Ask
            </TableHead>
          </TableRow>
          <TableRow>
            <TableHead>MM</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Size</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.ask?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.marketMaker}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{formatNumberInput(item.size)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
