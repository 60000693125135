import { useEffect, useState, useCallback } from "react";
import { Stock } from "../models/Stock";
import { fetchStockBySearchTerm } from "../util/Http";

const MIN_SEARCH_LENGTH = 2;
const DEBOUNCE_DELAY = 300;
const MAX_RESULTS = 10;

export default function useGetSymbolsOptions(searchTerm: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [symbolsOptions, setSymbolsOptions] = useState<string[]>([]); // Initialize with empty array

  const validateSearchTerm = useCallback((term: string): boolean => {
    const cleanTerm = term.trim();
    if (cleanTerm.length < MIN_SEARCH_LENGTH) return false;
    return /^[a-zA-Z0-9\s]*$/.test(cleanTerm);
  }, []);

  useEffect(() => {
    setError(null);

    // Ensure we reset to empty array when input is invalid
    if (!validateSearchTerm(searchTerm)) {
      setSymbolsOptions([]);
      return;
    }

    const fetchSymbols = async () => {
      setIsLoading(true);

      try {
        const data = await fetchStockBySearchTerm(searchTerm);

        if (!Array.isArray(data)) {
          throw new Error("Invalid response format");
        }

        const processedSymbols = data
          .filter((stock: Stock): stock is Stock =>
            Boolean(stock?.symbol?.trim())
          )
          .map((stock) => stock.symbol.toUpperCase())
          .slice(0, MAX_RESULTS);

        setSymbolsOptions(processedSymbols || []); // Ensure we always set an array
      } catch (err) {
        setError("Failed to fetch stock symbols");
        setSymbolsOptions([]); // Reset to empty array on error
      } finally {
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchSymbols, DEBOUNCE_DELAY);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, validateSearchTerm]);

  return { symbolsOptions: symbolsOptions || [], isLoading, error }; // Ensure we never return undefined
}
