import { useState, FormEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Loader2 } from "lucide-react";
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "./command";
import useGetSymbolsOptions from "../../hooks/useGetSymbolsOptions";

const Search = () => {
  const [inputValue, setInputValue] = useState("");
  const { symbolsOptions, isLoading, error } = useGetSymbolsOptions(inputValue);
  const navigate = useNavigate();

  const handleOptionSelect = useCallback(
    (value: string) => {
      const trimmedValue = value?.trim().toLowerCase();
      if (trimmedValue) {
        navigate(`/stocks/${trimmedValue}`);
        setInputValue("");
      }
    },
    [navigate]
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (Array.isArray(symbolsOptions) && symbolsOptions.length === 1) {
      handleOptionSelect(symbolsOptions[0]);
    }
  };

  const getHighlightedText = useCallback(
    (text: string) => {
      const cleanInput = inputValue?.trim().toLowerCase() ?? "";
      const cleanText = text?.toLowerCase() ?? "";
      const matchStart = cleanText.indexOf(cleanInput);

      if (matchStart === -1) return { before: text, match: "", after: "" };

      return {
        before: text.slice(0, matchStart),
        match: text.slice(matchStart, matchStart + cleanInput.length),
        after: text.slice(matchStart + cleanInput.length),
      };
    },
    [inputValue]
  );

  const renderContent = () => {
    if (!inputValue?.trim()) {
      return null;
    }

    if (isLoading) {
      return (
        <div className="py-6 text-center">
          <Loader2 className="h-4 w-4 animate-spin mx-auto" />
        </div>
      );
    }

    if (error) {
      return (
        <CommandEmpty className="py-6 text-center text-sm text-red-500">
          {error}
        </CommandEmpty>
      );
    }

    // Ensure symbolsOptions is an array
    const options = Array.isArray(symbolsOptions) ? symbolsOptions : [];

    if (options.length === 0) {
      return (
        <CommandEmpty className="py-6 text-center text-sm">
          No matching stocks found.
        </CommandEmpty>
      );
    }

    return (
      <CommandGroup>
        {options.map((option) => {
          if (!option) return null;
          const { before, match, after } = getHighlightedText(option);

          return (
            <CommandItem
              key={option}
              value={option}
              onSelect={handleOptionSelect}
              className="px-4 py-3 cursor-pointer hover:bg-accent flex items-center justify-between group"
            >
              <span>
                {before}
                {match && <span className="font-semibold">{match}</span>}
                {after}
              </span>
              <ArrowRight className="h-4 w-4 opacity-0 group-hover:opacity-50 transition-opacity" />
            </CommandItem>
          );
        })}
      </CommandGroup>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 mt-40 space-y-6">
      <div className="text-center space-y-2">
        <h1 className="text-3xl md:text-4xl font-bold">
          Search <span className="italic">Any</span> OTC Stock
        </h1>
        <p className="text-sm md:text-base text-muted-foreground">
          Explore data, trends, and insights.
        </p>
      </div>

      <div className="relative w-full max-w-2xl">
        <form onSubmit={handleSubmit}>
          <Command
            className="rounded-lg border shadow-sm overflow-hidden bg-slate-300/50 dark:bg-slate-700/50 transition-colors duration-200 focus-within:bg-background"
            shouldFilter={false}
          >
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              placeholder="Search"
              className="h-11 text-base"
            />

            <CommandList>{renderContent()}</CommandList>
          </Command>
        </form>
      </div>
    </div>
  );
};

export default Search;
