import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu as MenuIcon, Search as SearchIcon, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Button } from "./button";
import useGetSymbolsOptions from "../../hooks/useGetSymbolsOptions";
import { ThemeToggle } from "../theme-toggle";

const SearchAppBar = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [searchOpen, setSearchOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { symbolsOptions, isLoading, error } = useGetSymbolsOptions(inputValue);
  const navigate = useNavigate();

  const handleOptionSelect = (value: string) => {
    navigate(`/stocks/${value.trim()}`);
    setInputValue("");
    setSearchOpen(false);
  };

  const handleLogin = () => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      logout();
    }
  };

  return (
    <div className="border-b">
      <div className="flex h-16 items-center px-4">
        <div className="flex items-center space-x-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <MenuIcon className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-48">
              <DropdownMenuItem onClick={() => navigate("/")}>
                Home
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  (window.location.href = "https://twitter.com/aseallseeingeye")
                }
              >
                Twitter
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  (window.location.href =
                    "https://telegram.me/ase_otc_bot?start=deocto_main")
                }
              >
                Telegram
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="flex flex-1 items-center justify-end space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setSearchOpen(true)}
            className="px-2"
          >
            <SearchIcon className="h-5 w-5" />
          </Button>

          <CommandDialog open={searchOpen} onOpenChange={setSearchOpen}>
            <Command shouldFilter={false}>
              <CommandInput
                placeholder="Search stocks..."
                value={inputValue}
                onValueChange={setInputValue}
              />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {symbolsOptions.map((option) => {
                    // Find the match position
                    const matchIndex = option
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase());
                    if (matchIndex === -1) {
                      return (
                        <CommandItem
                          key={option}
                          value={option}
                          onSelect={handleOptionSelect}
                        >
                          {option}
                        </CommandItem>
                      );
                    }

                    const before = option.slice(0, matchIndex);
                    const match = option.slice(
                      matchIndex,
                      matchIndex + inputValue.length
                    );
                    const after = option.slice(matchIndex + inputValue.length);

                    return (
                      <CommandItem
                        key={option}
                        value={option}
                        onSelect={handleOptionSelect}
                        className="gap-0"
                      >
                        {before}
                        <span className="font-semibold">{match}</span>
                        {after}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </CommandDialog>

          <ThemeToggle />

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="ml-2">
                <User className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-48">
              <DropdownMenuItem onClick={handleLogin}>
                {isAuthenticated ? "Sign out" : "Sign in"}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default SearchAppBar;
