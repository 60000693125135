import { Card, CardContent, CardHeader, CardTitle } from "./card";
import TradingViewWidget from "./TradingViewWidget";

interface StockSymbol {
  stock: string;
}

export default function StockOverview({ stock }: StockSymbol) {
  return (
    <Card className="relative overflow-hidden">
      <CardContent className="mt-4">
        <h1 className="font-semibold">Price History</h1>
        <div className="h-[270px] md:h-[500px] mt-4">
          <TradingViewWidget stock={stock} />
        </div>
      </CardContent>
    </Card>
  );
}
